<template>
  <div class="relative">
    <div v-loading="isLoading || isRendering">
      <div class="text-black">
        <div class="p-2 cursor-none cs-container">
          <div :id="containerID" class="pdf-container cs-cursor">
            <div v-for="(signatory, index) in signatories" :key="index">
              <div
                v-if="
                  signatory &&
                  signatory.signatory_metadata &&
                  (!signatory.signatory_metadata.signature ||
                    !signatory.signatory_metadata.signature.id)
                "
              >
                <div
                  :id="`${imageID}_${signatory.id}_default`"
                  class="cs-default-signature cursor-pointer"
                >
                  <div class="flex gap-2 items-center justify-center">
                    <div class="rounded-full overflow-hidden">
                      <img :src="getAvatar(signatory)" width="30" height="30" />
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill="currentColor"
                        d="M12.021 2.772a2.445 2.445 0 1 1 3.458 3.457L14.207 7.5l.086.086a2 2 0 0 1 0 2.829l-1.44 1.439a.5.5 0 0 1-.707-.707l1.44-1.44a1 1 0 0 0 0-1.414l-.086-.086l-6.646 6.647a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.595-.643l1.25-3.75a.5.5 0 0 1 .12-.195l8.626-8.625Zm2.75.707a1.445 1.445 0 0 0-2.042 0L4.187 12.02l-.925 2.774l2.982-.745l8.527-8.527a1.445 1.445 0 0 0 0-2.043ZM3.217 16.867l.051.038C4.011 17.445 5.112 18 6.5 18c.743 0 1.424-.26 2.029-.604c.603-.344 1.163-.79 1.661-1.195l.117-.095c.464-.378.862-.701 1.228-.917c.403-.238.644-.268.807-.214c.265.088.416.293.679 1.169c.087.292.243.61.471.867c.229.257.569.49 1.008.49c.474 0 .943-.229 1.305-.442c.239-.141.495-.318.703-.46c.103-.07.193-.133.264-.18c.268-.173.494-.285.649-.353c.077-.034.136-.057.174-.07l.04-.014l.006-.002a.5.5 0 0 0-.281-.96h-.001l-.003.002l-.007.002l-.021.007l-.07.023a3.57 3.57 0 0 0-.24.096a5.602 5.602 0 0 0-.79.43c-.117.077-.23.154-.342.232a9.27 9.27 0 0 1-.589.385c-.341.202-.61.303-.797.303c-.06 0-.15-.03-.26-.154a1.34 1.34 0 0 1-.261-.49c-.24-.8-.5-1.556-1.32-1.83c-.588-.196-1.16.023-1.632.301c-.435.257-.892.63-1.337.992l-.13.106c-.502.407-1.004.804-1.526 1.102c-.52.296-1.027.473-1.534.473c-.746 0-1.396-.2-1.934-.47l-1.349.337ZM17.5 15.5l-.14-.48l.14.48Z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>

            <vue-pdf-embed
              :ref="elementID"
              :id="elementID"
              :source="urlPDF"
              @internal-link-clicked="handleClickedPage"
              @loaded="handleDocumentLoad"
              @rendered="handleDocumentRender"
            />
          </div>
        </div>
      </div>
      <div
        v-show="showPopup && signatureFocus"
        :style="popupStyle"
        class="popup flex flex-col gap-2"
      >
        <div class="font-bold">{{ signatureFocus.signatory_name }}</div>
        <div>{{ signatureFocus.signatory_email }}</div>
        <div :set="(imageSrc = handleGetImageBase64(signatureFocus))">
          <img
            v-if="imageSrc"
            style="width: 80px; height: 40px"
            :src="imageSrc"
          />
        </div>
        <div :set="(signAt = getSignAt(signatureFocus))">
          <div v-if="signAt">
            <strong class="mr-1">Đã ký vào:</strong>
            <i>{{ signAt }}</i>
          </div>
          <div v-else>
            <span class="mr-1">Trạng thái:</span>
            <i class="font-bold">Chưa ký</i>
            <!-- <i>{{ signAt }}</i> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
        
  <script>
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import SignatureRequest from '@/api/request/SignatureRequest'
import uploadS3File from '../../../utils/uploadS3File'
import { mixinSignPdf } from '../../../utils/mixinSignPdf'
import { mixinPdfViewer } from '../../../utils/mixinPdfViewer'

export default {
  name: 'EmrPDFViewer',
  components: { VuePdfEmbed },
  mixins: [mixinSignPdf, mixinPdfViewer],
  props: {
    elementIDProps: String,
    containerIDProps: String,
    imageIDProps: String,
    urlProp: String,
    docTypeProp: Number,
    docIDProp: Number,
    isDocBase64Prop: Boolean,
    isShowNotSignProp: Boolean,
    envelopeInfoProp: Object
  },
  data () {
    return {
      isLoading: false,
      pageCount: null,
      signatureImage: '',
      isRendering: true,
      canvasSelectedClicked: false,
      imageX: 80,
      imageY: 40,
      urlPDF: '',
      documentPDFViewer: null,
      metadata: null,
      documentType: null,
      documentID: null,
      envelopeInfo: {},
      showPopup: false,
      popupStyle: {},
      signatureFocus: {},
      elementID: 'PDFViewer',
      containerID: 'PDFViewContainer',
      imageID: 'PDFViewerImageID',
      signed_at: null,
      isDocBase64: false,
      signatureData: [],
      isShowNotSign: true
    }
  },
  computed: {
    request () {
      return new SignatureRequest()
    },
    signatories () {
      const envelope_signatories =
        this.envelopeInfo?.envelope_signatories || []
      const formatSignatories = envelope_signatories?.map((item) => {
        const signatureDataItem =
          this.signatureData?.find(
            (signature) =>
              signature?.signature_index ==
              item?.signatory_metadata?.signed_index
          ) || {}

        return {
          ...item,
          ...signatureDataItem
        }
      })
      const sortSignatories =
        formatSignatories?.sort(
          (a, b) => a?.signatory_order - b?.signatory_order
        ) || []

      if (!this.isShowNotSign) {
        return sortSignatories?.filter((item) => item?.status === 2)
      }

      return sortSignatories
    }
  },
  watch: {
    elementIDProps () {
      this.elementID = this.elementIDProps || 'PDFViewer'
    },
    containerIDProps () {
      this.containerID = this.containerIDProps || 'PDFViewContainer'
    },
    imageIDProps () {
      this.imageID = this.imageIDProps || 'PDFViewerImageID'
    }
  },

  created () {
    this.handleMapData()
  },
  destroyed () {
    this.handleClose()
  },
  mounted () {},
  methods: {
    handleMapData () {
      this.elementID = this.elementIDProps || 'PDFViewer'

      this.containerID = this.containerIDProps || 'PDFViewContainer'

      this.imageID = this.imageIDProps || 'PDFViewerImageID'

      this.$nextTick(() => {
        this.handleOpen(
          this.urlProp,
          this.docTypeProp,
          this.docIDProp,
          this.isDocBase64Prop,
          this.isShowNotSignProp
        )
      })
    },
    handleClose () {
      // const container = document.getElementById('PDFViewContainer')

      // container.removeEventListener('mousemove', (e) => {})

      // const canvases = container?.querySelectorAll('canvas')
      // canvases.forEach((item, index) => {
      //   item.removeEventListener('mousedown', function (event) {})
      // })

      this.urlPDF = ''
      this.documentPDFViewer = null
      this.metadata = null
      this.documentID = null
      this.documentType = null
      this.canvasSelectedClicked = false
      this.showPopup = false
      this.popupStyle = {}
      this.signatureFocus = {}
      this.isRendering = false
      this.$emit('onClose')
      // this.isRendering = true
    },
    async handleOpen (
      url,
      docType,
      documentID,
      isDocBase64Prop,
      isShowNotSignProps = true
    ) {
      this.isShowNotSign = isShowNotSignProps
      this.documentType = docType
      this.documentID = documentID
      this.envelopeInfo = this.envelopeInfoProp || {}

      if (this.envelopeInfo?.document_url) {
        const s3Url = await uploadS3File.getLinkVideoAWS(
          this.envelopeInfo?.document_url
        )
        this.urlPDF = s3Url
      } else {
        if (isDocBase64Prop) {
          const docBase64 = this.generatePDFSrcBase64(url)
          this.urlPDF = docBase64
          this.isDocBase64 = isDocBase64Prop
        } else {
          if (url.includes('https://')) {
            this.urlPDF = url
          } else {
            const s3Url = await uploadS3File.getLinkVideoAWS(url)
            this.urlPDF = s3Url
          }
        }
      }

      if (!this.envelopeInfo?.id) return

      await this.handleProcessDocument()

      this.$nextTick(() => {
        this.handleCheckSignatories()
      })
      // this.handleCheckMouseInPage()
    },
    handleDocumentLoad (documentPDF) {
      this.pageCount = documentPDF.numPages
      this.documentPDFViewer = documentPDF
    },
    handleDocumentRender () {
      // if (!this.isRendering) return
      // // this.handleCheckMouseInPage()

      this.isRendering = false
      this.handleCheckMouseInPage()
    },
    handleClickedPage (page) {
      console.log('clicked', page)
    },

    generateImageSrcBase64 (content) {
      const prefix = 'data:image/png;base64,'

      return prefix + content
    },
    handleCheckMouseInPage () {
      const container = document.getElementById(this.containerID)
      const canvases = container?.querySelectorAll('canvas')
      const self = this
      canvases.forEach((item, index) => {
        item.addEventListener('mousedown', function (event) {
          self.handleCalcPositionSignature(item, event, index + 1, true)
          self.canvasSelectedClicked = !self.canvasSelectedClicked
        })
      })

      canvases.forEach((item, index) => {
        item.addEventListener('mousemove', function (event) {
          self.handleCalcPositionSignature(item, event, index + 1, false)
        })
      })
    },
    async handleCalcPositionSignature (canvas, event, page, isClick) {
      if (isClick && this.showPopup) {
        this.showPopup = false
        return
      }

      const pageViewer = await this.getPDFPageViewer(page)

      // Lấy kích thước của canvas
      const rect = canvas.getBoundingClientRect()

      // Tính toán tọa độ x, y của chuột trên canvas
      const x = event.clientX - rect.left
      const y = event.clientY - rect.top

      // Tính toán tọa độ trên PDF từ tọa độ chuột trên canvas
      const viewport = pageViewer.getViewport({ scale: 1.0 })
      const pdfX = (x / rect.width) * viewport.width
      const pdfY = (y / rect.height) * viewport.height
      const pointY = parseInt(viewport.height - pdfY)
      const pointX = pdfX

      this.signatories.forEach((signatory) => {
        const [signLLx, signLLy, signURx, signURy] =
          signatory?.signatory_metadata?.signature_position_rectangle_pdf
            ?.split(',')
            ?.map((item) => parseInt(item))

        // Check if point focus in signLLx, signLLy, signURx, signURy
        if (
          pointX >= signLLx &&
          pointX <= signURx &&
          pointY >= signLLy &&
          pointY <= signURy &&
          page == signatory?.signatory_metadata?.signature_position_page
        ) {
          if (isClick) {
            this.showPopup = true
            const canvasHeight = rect.height
            const pageHeight = (page - 1) * canvasHeight
            this.popupStyle = {
              top: `${y + pageHeight - 200}px`,
              left: `${x}px`
            }
            this.signatureFocus = signatory
          } else {
            canvas.style.cursor = 'pointer' // Add this line to set the cursor to pointer
          }
        } else {
          // this.showPopup = false
          canvas.style.cursor = 'default' // Add this line to set the cursor to pointer
        }
      })
    },
    async getPDFPageViewer (pageNum) {
      const pageViewer = await this.documentPDFViewer.getPage(pageNum)
      return pageViewer
    },
    getSignAt (data) {
      return (
        data.signed_at ||
        (data.signatory_metadata?.sign_at
          ? window
            .moment(data.signatory_metadata?.sign_at)
            .format(' HH:mm:ss DD/MM/YYYY')
          : '')
      )
    },
    handleGetImageBase64 (data) {
      if (!data?.signatory_metadata?.signature?.signature_base64) return ''

      const prefix = 'data:image/png;base64,'
      const content = data?.signatory_metadata?.signature?.signature_base64
      return prefix + content
    },
    handlePrint () {
      this.$refs[this.elementID].print(
        400,
        this.getEnvelopeNameByType(this.documentType),
        true
      )
      // window.print()
    },
    handleDownload () {
      this.$refs[this.elementID].download(
        this.getEnvelopeNameByType(this.documentType)
      )
    }
  }
}
</script>
        
    <style lang="scss" scoped>
.vue-pdf-embed {
  margin: 0 auto;
}

.vue-pdf-embed__page {
  margin-bottom: 8px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
}
.cs-container {
  overflow: auto;
  // pointer-events: none;
}

.pdf-container {
  width: 1000px;
  border: 1px solid #000;
  margin: 0 auto;
  position: relative;
}

::v-deep {
  .vue-pdf-embed {
    .vue-pdf-embed__page {
      box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
    }
  }

  .el-dialog__header {
    padding: 0 !important;
  }

  .el-dialog__body {
    padding: 0 !important;
  }
}

#image {
  position: absolute;
  z-index: 1000;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.cs-sign {
  position: sticky;
  top: 0px;
  right: 20px;
  background-color: rgb(74, 74, 74);
  z-index: 100000;
}

.popup {
  position: absolute;
  background-color: white;
  z-index: 20000;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.cs-default-signature {
  position: absolute;
  z-index: 2000;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  border: 1px dashed #696969;
  padding: 4px;
  width: 80px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffc820;
  cursor: pointer !important;
}
</style>