<template>
  <div
    v-loading="isLoading"
    v-if="medicalRecordMain && medicalRecordMain.id && emrEnvelopes.isProcessed"
  >
    <EmrPDFViewer
      containerIDProps="Page1_Container_EmrPDFViewer"
      imageIDProps="Page1_Image_EmrPDFViewer"
      elementIDProps="Page1_Element_EmrPDFViewer"
      :urlProp="
        getEnvelopeURL(
          ENVELOPE_DOCUMENT_TYPE.MEDICAL_RECORD_MASTER_PATIENT_INFO
        )
      "
      :docTypeProp="ENVELOPE_DOCUMENT_TYPE.MEDICAL_RECORD_MASTER_PATIENT_INFO"
      :docIDProp="medicalRecordMain.id"
      :isDocBase64Prop="false"
      :isShowNotSignProp="false"
      :envelopeInfoProp="emrEnvelopes.page1"
    />
    <EmrPDFViewer
      containerIDProps="Page2_Container_EmrPDFViewer"
      imageIDProps="Page2_Image_EmrPDFViewer"
      elementIDProps="Page2_Element_EmrPDFViewer"
      :urlProp="
        getEnvelopeURL(ENVELOPE_DOCUMENT_TYPE.MEDICAL_RECORD_MASTER_A_BENH_AN)
      "
      :docTypeProp="ENVELOPE_DOCUMENT_TYPE.MEDICAL_RECORD_MASTER_A_BENH_AN"
      :docIDProp="medicalRecordMain.id"
      :isDocBase64Prop="false"
      :isShowNotSignProp="false"
      :envelopeInfoProp="emrEnvelopes.page2"
    />
    <EmrPDFViewer
      containerIDProps="Page3_Container_EmrPDFViewer"
      imageIDProps="Page3_Image_EmrPDFViewer"
      elementIDProps="Page3_Element_EmrPDFViewer"
      :urlProp="
        getEnvelopeURL(ENVELOPE_DOCUMENT_TYPE.MEDICAL_RECORD_MASTER_B_TONG_KET)
      "
      :docTypeProp="ENVELOPE_DOCUMENT_TYPE.MEDICAL_RECORD_MASTER_B_TONG_KET"
      :docIDProp="medicalRecordMain.id"
      :isDocBase64Prop="false"
      :isShowNotSignProp="false"
      :envelopeInfoProp="emrEnvelopes.page3"
    />
  </div>
</template>

<script>
import EmrPDFViewer from './EmrPDFViewer.vue'
import { ENVELOPE_DOCUMENT_TYPE } from '../../../utils/constants'

export default {
  name: 'ContainerEmrPDFViewer',
  components: { EmrPDFViewer },
  props: {
    medicalRecordMain: Object,
    refreshContainer: Number,
    isLoading: Boolean,
    emrEnvelopes: Object
  },
  data () {
    return {
      ENVELOPE_DOCUMENT_TYPE
    }
  },
  watch: {
    refreshContainer () {
      this.$forceUpdate()
    }
  },

  methods: {
    getEnvelopeURL (docType) {
      switch (docType) {
        case ENVELOPE_DOCUMENT_TYPE.MEDICAL_RECORD_MASTER_PATIENT_INFO:
          return (
            this.emrEnvelopes?.page1?.document_url ||
            this.medicalRecordMain?.patient_info_url
          )

        case ENVELOPE_DOCUMENT_TYPE.MEDICAL_RECORD_MASTER_A_BENH_AN:
          return (
            this.emrEnvelopes?.page2?.document_url ||
            this.medicalRecordMain?.a_benh_an_url
          )

        case ENVELOPE_DOCUMENT_TYPE.MEDICAL_RECORD_MASTER_B_TONG_KET:
          return (
            this.emrEnvelopes?.page3?.document_url ||
            this.medicalRecordMain?.b_tong_ket_url
          )
        // break;

        default:
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>