<template>
  <div class="mt-6">
    <div class="text-2xl font-semibold">I. HÀNH CHÍNH</div>

    <div class="mt-2.5 row">
      <div class="col-md-6 row">
        <div class="col-md-9">
          <label class="fs-14 txt-grey-900">4. Nghề nghiệp</label>
          <input
            v-model="formData.career_name"
            :readonly="!isEdit"
            type="text"
            class="form--input txt-grey-900"
          />
        </div>
        <div class="col-md-3">
          <label class="fs-14 txt-grey-900">Mã</label>
          <input
            v-model="formData.career_code"
            :readonly="!isEdit"
            type="text"
            class="form--input txt-grey-900"
          />
        </div>
      </div>
      <div class="col-md-6 row md:ml-2">
        <div class="col-md-9">
          <label class="fs-14 txt-grey-900">5. Dân tộc</label>
          <input
            v-model="formData.ethnic_name"
            :readonly="!isEdit"
            type="text"
            class="form--input txt-grey-900"
          />
        </div>
        <div class="col-md-3">
          <label class="fs-14 txt-grey-900">Mã</label>
          <input
            v-model="formData.ethnic_code"
            :readonly="!isEdit"
            type="text"
            class="form--input txt-grey-900"
          />
        </div>
      </div>
    </div>

    <div class="mt-2.5">
      <div class="col-md-12 row">
        <div class="col-md-9">
          <label class="fs-14 txt-grey-900">6. Ngoại kiều</label>
          <input
            v-model="formData.nationality_name"
            :readonly="!isEdit"
            type="text"
            class="form--input txt-grey-900"
          />
        </div>
        <div class="col-md-3">
          <label class="fs-14 txt-grey-900">Mã</label>
          <input
            v-model="formData.nationality_code"
            :readonly="!isEdit"
            type="text"
            class="form--input txt-grey-900"
          />
        </div>
      </div>
    </div>

    <div class="row mt-2.5">
      <div class="col-md-12 md:pr-6">
        <label class="fs-14 txt-grey-900">7. Địa chỉ số nhà</label>
        <input
          v-model="formData.address_detail"
          :readonly="!isEdit"
          type="text"
          class="form--input txt-grey-900"
        />
      </div>
    </div>

    <div class="row mt-2.5">
      <div class="col-md-6 pl-2 md:pr-6">
        <label class="fs-14 txt-grey-900">Xã, phường</label>
        <input
          v-model="formData.address_wards_name"
          :readonly="!isEdit"
          type="text"
          class="form--input txt-grey-900"
        />
      </div>
      <div class="col-md-6 md:pl-3">
        <div>
          <label class="fs-14 txt-grey-900">9. Đối tượng BHYT</label>
        </div>
        <div class="flex mt-1.5">
          <label for="BHYT" class="d-flex align-items-center mr-3 txt-grey-800">
            <input
              :disabled="!isEdit"
              type="radio"
              id="BHYT"
              :value="1"
              class="mr-2"
              v-model="formData.insurance_type"
            />1.BHYT
          </label>
          <label for="fee" class="d-flex align-items-center mr-3 txt-grey-800">
            <input
              :disabled="!isEdit"
              type="radio"
              id="fee"
              :value="2"
              class="mr-2"
              v-model="formData.insurance_type"
            />2.Thu phí
          </label>
          <label for="free" class="d-flex align-items-center mr-3 txt-grey-800">
            <input
              :disabled="!isEdit"
              type="radio"
              id="free"
              :value="3"
              class="mr-2"
              v-model="formData.insurance_type"
            />3.Miễn
          </label>
          <label for="other" class="d-flex align-items-center mr-3 txt-grey-800">
            <input
              :disabled="!isEdit"
              type="radio"
              id="other"
              :value="4"
              class="mr-2"
              v-model="formData.insurance_type"
            />4.Khác
          </label>
          <label for="reset" class="d-flex align-items-center txt-grey-800">
            <button
              @click="formData.insurance_type = null"
              class="bg-blue-500 hover:bg-blue-700 text-white rounded-full border-0 whitespace-nowrap"
            >Chọn lại</button>
          </label>
        </div>
      </div>
    </div>

    <div class="row mt-2.5">
      <div class="col-md-6 row">
        <div class="col-md-9">
          <label class="fs-14 txt-grey-900">Huyện (Q, Tx)</label>
          <input
            v-model="formData.address_district_name"
            :readonly="!isEdit"
            type="text"
            class="form--input txt-grey-900"
          />
        </div>
        <div class="col-md-3">
          <label class="fs-14 txt-grey-900">Mã</label>
          <input
            v-model="formData.address_district_code"
            :readonly="!isEdit"
            type="text"
            class="form--input txt-grey-900"
          />
        </div>
      </div>
      <div class="col-md-6 md:pl-6">
        <label class="fs-14 txt-grey-900">Số thẻ BHYT</label>
        <input
          v-model="formData.insurance_number"
          :readonly="!isEdit"
          type="text"
          class="form--input txt-grey-900"
        />
      </div>
    </div>

    <div class="row mt-2.5">
      <div class="col-md-6 row">
        <div class="col-md-9">
          <label class="fs-14 txt-grey-900">Tỉnh, thành phố</label>
          <input
            v-model="formData.address_province_name"
            :readonly="!isEdit"
            type="text"
            class="form--input txt-grey-900"
          />
        </div>
        <div class="col-md-3">
          <label class="fs-14 txt-grey-900">Mã</label>
          <input
            v-model="formData.address_province_code"
            :readonly="!isEdit"
            type="text"
            class="form--input txt-grey-900"
          />
        </div>
      </div>
      <div class="col-md-6 md:pl-6">
        <label class="fs-14 txt-grey-900">10. BHYT giá trị đến</label>
        <input
          v-model="insuranceExpire"
          :readonly="!isEdit"
          type="date"
          class="form--input txt-grey-900"
        />
      </div>
    </div>

    <div class="row mt-2.5">
      <div class="col-md-6 md:pr-6">
        <label class="fs-14 txt-grey-900">8. Nơi làm việc</label>
        <input
          v-model="formData.work_at"
          :readonly="!isEdit"
          type="text"
          class="form--input txt-grey-900"
        />
      </div>
      <div class="col-md-6">
        <label class="fs-14 txt-grey-900">11. Họ tên, địa chỉ người nhà khi cần báo tin</label>
        <input
          v-model="formData.emergency_contact"
          :readonly="!isEdit"
          type="text"
          class="form--input txt-grey-900"
        />
      </div>
    </div>

    <div class="row mt-2.5">
      <div class="col-md-6 md:pr-6">
        <label class="fs-14 txt-grey-900">Số lưu trữ</label>
        <input
          v-model="formData.archive_number"
          :readonly="!isEdit"
          type="text"
          class="form--input txt-grey-900"
        />
      </div>
    </div>
  </div>
</template>

<script>
import appUtils from '../../../utils/appUtils'

export default {
  name: 'HanhChinh',
  props: ['form', 'isEdit'],
  data () {
    return {
      formData: this.form || null,
      appUtils
    }
  },
  mounted () {
    this.formData = this.form
  },
  computed: {
    insuranceExpire () {
      if (this.formData?.insurance_expire === '') return

      return appUtils.formatInputDate(this.formData.insurance_expire)
    }
  }
}
</script>
